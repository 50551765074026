import { APP_INITIALIZER, inject } from '@angular/core';
import { ShipmentVehicleDataAccessService } from '@nest/domain/delivery/shipments/frontend/data-access';
import { ShipmentVehicleDto, ShipmentVehicleEntityName } from '@nest/domain/entities-metadata/dtos';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

const paths = autoPathsFor<ShipmentVehicleDto>();

export const ShipmentVehiclesDefaultElements = fieldsToElements(paths(['createdAt', 'name', 'shipmentMaximumCapacity']));

export function provideShipmentVehicleEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				const entityMetadata = inject(EntityMetadataService);
				const shipmentVehicleDataAccess = inject(ShipmentVehicleDataAccessService);

				return () => {
					entityMetadata.registerEntity({
						entityName: ShipmentVehicleEntityName,
						dataAccess: shipmentVehicleDataAccess,
						defaultElements: ShipmentVehiclesDefaultElements
					});
				};
			}
		}
	];
}
